import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense, lazy } from 'react';
import './App.css';
import LazyToastContainer from "./LazyToastContainer";
import Loader from "./utilityComponent/Loader";
import DemoSidebar from "./adminComponents/DemoSidebar";
import SoftmintQr from "./utilityComponent/SoftmintQr";

// API USER

const Dashboard = lazy(() => import('./components/Dashboard'));
const Sidebar = lazy(() => import('./components/Sidebar'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const UpitxnOnline = lazy(() => import('./components/UpitxnOnline'));
const UpiSettlementReport = lazy(() => import('./components/UpiSettlementReport'));
const IpWhiteListing = lazy(() => import('./components/IpWhiteListing'));
const LandingPage = lazy(() => import('./components/LandingPage'));
const Homepage = lazy(() => import('./components/Homepage'));
const FAQ = lazy(() => import('./components/FAQ'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const ApiUserPlanReport = lazy(() => import('./components/ApiUserPlanReport'));
const RegistrationPage = lazy(() => import('./components/RegistrationPage'));

// ADMIN
const UserRegister = lazy(() => import('./adminComponents/UserRegister'));
const AdminSidebar = lazy(() => import('./adminComponents/AdminSidebar'));
const FetchUsers = lazy(() => import('./adminComponents/FetchUsers'));
const Edituser = lazy(() => import('./adminComponents/Edituser'));
const Kyc = lazy(() => import('./adminComponents/Kyc'));
const KycReport = lazy(() => import('./adminComponents/KycReport'));
const SubSeller = lazy(() => import('./adminComponents/SubSeller'));
const AdminDashboard = lazy(() => import('./adminComponents/AdminDashboard'));
const Graph = lazy(() => import('./adminComponents/Graph'));
const OnlineUpiTxnReport = lazy(() => import('./adminComponents/OnlineUpiTxnReport'));
const Payment = lazy(() => import('./adminComponents/Payment'));
const PlanReport = lazy(() => import('./adminComponents/PlanReport'));
const AddPlan = lazy(() => import('./adminComponents/AddPlan'));
const UpdatePlan = lazy(() => import('./adminComponents/UpdatePlan'));
const AdminSettlementReport = lazy(() => import('./adminComponents/AdminSettlementReport'));
const OfflineTxnReport = lazy(() => import('./adminComponents/OfflineTxnReport'));
const SellerReport = lazy(() => import('./adminComponents/SellerReport'));
const DownlineSellerReport = lazy(() => import('./adminComponents/DownlineSellerReport'));
const AddEmployee = lazy(() => import('./adminComponents/AddEmployee'));
const AdminUpiOnboardingReport = lazy(() => import('./adminComponents/AdminUpiOnboardingReport'));
const UpiTxnReport = lazy(() => import('./adminComponents/UpiTxnReport'));
const EditUpiOnboarding = lazy(() => import('./adminComponents/EditUpiOnboarding'));
const FetchBank = lazy(() => import('./adminComponents/FetchBank'));
const RegisterUser = lazy(() => import('./adminComponents/RegisterUser'));

// FRANCHISE

const EmployeeDashboard = lazy(() => import('./Franchise_Component/EmployeeDashboard'));
const EmployeeNavbar = lazy(() => import('./Franchise_Component/EmployeeNavbar'));
const Users = lazy(() => import('./Franchise_Component/Users'));
const AddUser = lazy(() => import('./Franchise_Component/AddUser'));
const UpiOnboardingReport = lazy(() => import('./Franchise_Component/UpiOnboardingReport'));
const FranchiseUpiTxnReport = lazy(() => import('./Franchise_Component/FranchiseUpiTxnReport'));


//DISTRIBUTOR

const DistributorNav = lazy(() => import('./Distributor/DistributorNav'));
const DistributorDash = lazy(() => import('./Distributor/DistributorDash'));

// Reseller
const ResellerNav = lazy(() => import('./Reseller/ResellerNav'));
const MainSec = lazy(() => import('./Reseller/MainSec'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div><Loader /></div>}>
        <LazyToastContainer />
        {/* <DemoSidebar /> */}

        {/* Api User */}
        <Routes>
          <Route path='/sidebar' element={<Sidebar />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='upisettlementreport' element={<UpiSettlementReport />} />
          <Route path='ipwhitelisting' element={<IpWhiteListing />} />
          <Route path='/' element={<LandingPage />} />
          <Route path="/upitnonline" element={<UpitxnOnline />} />
          <Route path='homepage' element={<Homepage />} />
          <Route path='faq' element={<FAQ />} />
          <Route path='contactus' element={<ContactUs />} />
          <Route path='apiuserplanreport' element={<ApiUserPlanReport />} />
          <Route path='registrationpage' element={<RegistrationPage />} />
          <Route path='qr' element={<SoftmintQr />} />

          {/* Admin  */}
          <Route path='/userregister' element={<UserRegister />} />
          <Route path='/adminsidebar' element={<AdminSidebar />} />
          <Route path='/fetchUsers' element={<FetchUsers />} />
          <Route path='/edituser' element={<Edituser />} />
          <Route path='/demosidebar' element={<DemoSidebar />} />
          <Route path='/kyc' element={<Kyc />} />
          <Route path='/kycreport' element={<KycReport />} />
          <Route path='/subseller' element={<SubSeller />} />
          <Route path='/admindashboard' element={<AdminDashboard />} />
          <Route path='/graph' element={<Graph />} />
          <Route path='/onlineupitxnreport' element={<OnlineUpiTxnReport />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/upitxnonline' element={<UpitxnOnline />} />
          <Route path='planreport' element={<PlanReport />} />
          <Route path='addplan' element={<AddPlan />} />
          <Route path='updateplan' element={<UpdatePlan />} />
          <Route path='adminsettlement' element={<AdminSettlementReport />} />
          <Route path='offlinetxnreport' element={<OfflineTxnReport />} />
          <Route path='sellerreport' element={<SellerReport />} />
          <Route path='addemployee' element={<AddEmployee />} />
          <Route path='downlinesellerreport' element={<DownlineSellerReport />} />
          <Route path='/adminupionboardingreport' element={<AdminUpiOnboardingReport />} />
          <Route path='/upitxnreport' element={<UpiTxnReport />} />
          <Route path='/editupionboarding' element={<EditUpiOnboarding />} />
          <Route path='/fetchbank' element={<FetchBank />} />
          <Route path='/registeruser' element={<RegisterUser />} />

          {/* FRANCHISE */}

          <Route path='/employeedashboard' element={<EmployeeDashboard />} />
          <Route path='/employeeNavbar' element={<EmployeeNavbar />} />
          <Route path='/users' element={<Users />} />
          <Route path='/adduser' element={<AddUser />} />
          <Route path='/upionboardingreport' element={<UpiOnboardingReport />} />
          <Route path='/franchiseupitxnreport' element={<FranchiseUpiTxnReport />} />


          {/* DISTRIBUTOR */}

          <Route path='/distributornav' element={<DistributorNav />} />
          <Route path='/distributordash' element={<DistributorDash />} />

          {/* Reseller */}
          <Route path='/resellernav' element={<ResellerNav />} />
          <Route path='/mainsec' element={<MainSec />} />

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
